import {useContext} from "react";
import {LanguageContext} from "./LanguageContext";

const useLanguage = () => {
    const contex = useContext(LanguageContext);
    if (!contex) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return contex;
};

export default useLanguage;
