import {useState} from "react";
import {LanguageContext} from "./LanguageContext";

const LanguageProvider = ({children}) => {
    const [currentLanguage, setLanguage] = useState("DE");
    return (
        <LanguageContext.Provider value={{currentLanguage, setLanguage}}>
            {children}
        </LanguageContext.Provider>
    );
};
export default LanguageProvider;
