import React from "react";
import styles from "./Ellipse.module.scss";
import arrow from "../../asset/logo/Vector-ellips.svg";
import whiteEllipse from "../../asset/img/WhiteEllipse.svg";
import secondWhiteEllipse from "../../asset/img/SecondEllipse.svg";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import {useEffect, useRef} from "react";
import useLanguage from "../Context/useLanguage";

gsap.registerPlugin(ScrollTrigger);

function Ellipse() {
    const {currentLanguage} = useLanguage();
    const ellipse = useRef(null);
    useEffect(() => {
        const el1 = ellipse.current;
        gsap.fromTo(
            el1,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
    }, []);

    return (
        <a
            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/portfolio`}
            className={styles["to-portfolio-block"]}
            ref={ellipse}
            target="_blank">
            <img src={whiteEllipse} alt="white-ellipse" className={styles["white-ellipse"]}/>
            <img src={secondWhiteEllipse} alt="second-white-ellipse" className={styles["second-white-ellipse"]}/>
            <div className={styles["ellipse-content"]}>
                <div className={styles["text-ellipse"]}>
                    <TranslatableText
                        dictionary={{
                            EN: 'See more in<br> our Portfolio',
                            DE: 'Mehr in unserem<br> Portfolio',
                        }}
                    ></TranslatableText>
                </div>
                <img src={arrow} alt="vector" className={styles.vector3}/>
            </div>
        </a>
    );
}

export default Ellipse;
