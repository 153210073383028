export const ERROR = 'error';
export const SUCCESS = 'success';
export const BUSINESS_BUTTONS = [
    {
        id: 1,
        englishContent: "Chat",
        germanContent: "Chat"
    },
    {
        id: 2,
        englishContent: "GPS",
        germanContent: "GPS"
    },
    {
        id: 3,
        englishContent: "User cabinet",
        germanContent: "Nutzerkonto"
    },
    {
        id: 4,
        englishContent: "Offline/online mode",
        germanContent: "Offline/Online-Modus"
    },
    {
        id: 5,
        englishContent: "Speaker",
        germanContent: "Mikrofon"
    },
    {
        id: 6,
        englishContent: "In-app purchases",
        germanContent: "In-App-Käufe"
    },
    {
        id: 7,
        englishContent: "Maps",
        germanContent: "Karten"
    },
    {
        id: 8,
        englishContent: "Multi-client capability",
        germanContent: "Mandantenfähigkeit"
    },
    {
        id: 9,
        englishContent: "Bluetooth",
        germanContent: "Bluetooth"
    },
    {
        id: 10,
        englishContent: "Calendar functions",
        germanContent: "Kamerafunktionen"
    },
    {
        id: 11,
        englishContent: "Camera functions",
        germanContent: "Kalenderfunktion"
    }
]