import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    values: []
};

export const nameSlice = createSlice({
    name: 'name',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.values = cloneDeep(action.payload);
        }
    }
});

export default nameSlice.reducer;

export const {setName} = nameSlice.actions;
