import React, {useRef, useEffect} from "react";
import style from "../Footer/Footer.module.scss";
import logo from "../../asset/logo/NITGLogo.svg";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import useLanguage from "../Context/useLanguage";

gsap.registerPlugin(ScrollTrigger);

function Footer() {
    const {currentLanguage} = useLanguage();
    const blockOne = useRef(null);
    const blockTwo = useRef(null);
    const blockThree = useRef(null);
    const blockFour = useRef(null);
    useEffect(() => {
        const el1 = blockOne.current;
        const el2 = blockTwo.current;
        const el3 = blockThree.current;
        const el4 = blockFour.current;
        gsap.fromTo(
            el1,
            {x: 300, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {x: -300, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {x: 300, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );
        gsap.fromTo(
            el4,
            {x: -300, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );
    }, []);

    return (
        <div className={style.footer}>
            <div className={style["container-top-wrapper"]}>
                <div className={style["container-top"]}>
                    <div ref={blockFour} className={style["logo-block"]}>
                        <a href="#Main">
                            <img src={logo} alt="logo" className={style.logo}/>
                        </a>
                    </div>

                    <a href="tel: +49(156)78573735" className={style.phone} ref={blockOne}>
                        +49(156)78573735
                    </a>
                </div>
            </div>
            <div className={style["container-bottom-wrapper"]}>
                <div className={style["container-bottom"]}>
                    <a
                        href="mailto:mail@northitgroup.com"
                        className={style.email}
                        ref={blockTwo}
                    >
                        office@northitgroup.com
                    </a>
                    <div ref={blockThree} className={style["policy-block"]}>
                        <a
                            className={style["link-imprint"]}
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/imprint`}
                            target="_blank"
                        >
                            <TranslatableText
                                dictionary={{
                                    EN: "Imprint",
                                    DE: "Impressum",
                                }}
                            ></TranslatableText>
                        </a>
                        <a
                            className={style["link-data"]}
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/data-policy`}
                            target="_blank"
                        >
                            <TranslatableText
                                dictionary={{
                                    EN: "Data policy",
                                    DE: "Datenschutz",
                                }}
                            ></TranslatableText>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
