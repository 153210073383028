import React, {useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./App.css";
import Header from "./Components/Header";
import Main from "./Components/MainPage";
import Capabilities from "./Components/Capabilities";
import Team from "./Components/Team";
import Advantages from "./Components/Advantages";
import Advertising from "./Components/Advertising";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import useLanguage from "./Components/Context/useLanguage";

function App() {
    const {currentLanguage, setLanguage} = useLanguage();
    useEffect(() => {
        const languageFromLocalStorage = localStorage.getItem('lang');
        if (!languageFromLocalStorage) {
            localStorage.setItem('lang', currentLanguage);
        } else {
            setLanguage(languageFromLocalStorage)
        }
    }, []);

    return (
        <div className="App">
            <Header/>
            <Main/>
            <Capabilities/>
            <Team/>
            <Advantages/>
            <Advertising/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;