import React, {useRef, useEffect} from "react";
import styles from "../Capabilities/Capabilities.module.scss";
import Native from "../../asset/logo/circle.svg";
import Web from "../../asset/logo/web-circle.svg";
import hybrid from "../../asset/logo/hybride-circle.svg";
import nativeAppPhones from "../../asset/img/nativeAppPhones.png";
import comp from "../../asset/img/mockup.png";
import laptopAndPhone from "../../asset/img/laptopAndPhone.png";
import Title from "../Title";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import Ellipse from "../Ellipse";
import useLanguage from "../Context/useLanguage";

gsap.registerPlugin(ScrollTrigger);

function Capabilities() {
    const {currentLanguage} = useLanguage();
    const blockOne = useRef(null);
    const native = useRef(null);
    const nativeVector = useRef(null);
    const web = useRef(null);
    const webVector = useRef(null);
    const Hybrid = useRef(null);
    const HybridVector = useRef(null);
    const block1 = useRef(null);
    const block2 = useRef(null);
    const block3 = useRef(null);
    const Tex1 = useRef(null);
    const Tex2 = useRef(null);
    const Tex3 = useRef(null);
    const Tex4 = useRef(null);
    const Tex5 = useRef(null);
    const Tex6 = useRef(null);
    const Tex7 = useRef(null);
    const Tex8 = useRef(null);
    const Tex9 = useRef(null);
    const Tex10 = useRef(null);
    const Phone1 = useRef(null);
    const Phone2 = useRef(null);
    const Phone3 = useRef(null);
    const Phone4 = useRef(null);
    const Comp1 = useRef(null);
    const Comp2 = useRef(null);

    useEffect(() => {
        const el1 = blockOne.current;
        const el2 = native.current;
        const el3 = nativeVector.current;
        const el4 = block1.current;
        const el5 = block2.current;
        const el6 = block3.current;
        const el7 = web.current;
        const el8 = webVector.current;
        const el9 = Hybrid.current;
        const el10 = HybridVector.current;
        const el11 = Tex1.current;
        const el12 = Tex2.current;
        const el13 = Tex3.current;
        const el14 = Tex4.current;
        const el15 = Tex5.current;
        const el16 = Phone1.current;
        const el17 = Phone2.current;
        const el18 = Phone3.current;
        const el20 = Comp1.current;
        const el21 = Comp2.current;
        const el22 = Phone4.current;
        const el23 = Tex6.current;
        const el24 = Tex7.current;
        const el25 = Tex8.current;
        const el26 = Tex9.current;
        const el27 = Tex10.current;

        gsap.fromTo(
            el1,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {x: -150, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {x: -150, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );
        gsap.fromTo(
            el4,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );
        gsap.fromTo(
            el5,
            {x: -350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el5,
                },
            }
        );
        gsap.fromTo(
            el6,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
        );
        gsap.fromTo(
            el7,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el7,
                },
            }
        );
        gsap.fromTo(
            el8,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el8,
                },
            }
        );
        gsap.fromTo(
            el9,
            {x: -350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el9,
                },
            }
        );
        gsap.fromTo(
            el10,
            {x: -350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el10,
                },
            }
        );
        gsap.fromTo(
            el11,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el11,
                },
            }
        );
        gsap.fromTo(
            el12,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el12,
                },
            }
        );
        gsap.fromTo(
            el13,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el13,
                },
            }
        );
        gsap.fromTo(
            el14,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el14,
                },
            }
        );
        gsap.fromTo(
            el15,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el15,
                },
            }
        );
        gsap.fromTo(
            el16,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el16,
                },
            }
        );
        gsap.fromTo(
            el17,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el17,
                },
            }
        );
        gsap.fromTo(
            el18,
            {x: 250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el18,
                },
            }
        );
        gsap.fromTo(
            el20,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el20,
                },
            }
        );
        gsap.fromTo(
            el21,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el21,
                },
            }
        );
        gsap.fromTo(
            el22,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el22,
                },
            }
        );
        gsap.fromTo(
            el23,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el23,
                },
            }
        );
        gsap.fromTo(
            el24,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el24,
                },
            }
        );
        gsap.fromTo(
            el25,
            {x: 350, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el25,
                },
            }
        );
        gsap.fromTo(
            el26,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el26,
                },
            }
        );
        gsap.fromTo(
            el27,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el17,
                },
            }
        );
    }, []);

    return (
        <div className={styles["capabilities-content"]} id="App">
            <Title className={styles["capabilities-title"]}>
                <TranslatableText
                    dictionary={{
                        EN: "App types",
                        DE: "App-Typen",
                    }}
                ></TranslatableText>
            </Title>

            <div className={styles["native-app-block"]}>
                <div className={styles["stroke-native"]}>
                    <img
                        src={Native}
                        alt="vector"
                        ref={nativeVector}
                    />
                    <h2 ref={native}>
                        <TranslatableText
                            dictionary={{
                                EN: "NATIVE APP",
                                DE: "NATIVE APP",
                            }}
                        ></TranslatableText>
                    </h2>
                </div>
                <div className={styles["rectangle-native-block"]}>
                    <div className={styles["rectangle-native"]} ref={block1}>
                        <div className={styles["text-native"]} ref={Tex1}>
                            <TranslatableText
                                dictionary={{
                                    EN: 'Developed for an operating system (iOS, Android, Windows)',
                                    DE: 'Entwickelt für ein Betriebssystem (iOS, Android, Windows)',
                                }}
                            ></TranslatableText>
                            <div
                                className={styles["view-native"]}
                                ref={Tex5}
                            >
                                <TranslatableText
                                    dictionary={{
                                        EN: "View native app example:",
                                        DE: "Beispiel ansehen:",
                                    }}
                                ></TranslatableText>
                            </div>
                        </div>
                        <div className={styles["phones-block"]}>
                            <a
                                href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/megaacc`}
                                className={styles["phones"]}
                                ref={Phone1}
                                target="_blank"
                            >
                                <img src={nativeAppPhones} alt="phone" ref={Phone1} className={styles["phones-image"]}/>
                                <span className={styles["first-phone-signature"]}>
                                    <div className={styles.icon1}>
                                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.46435 13.677C4.26846 13.2658 3.07257 12.8546 1.87365 12.4423C1.215 12.2158 0.905482 11.5872 1.13227 10.9295C1.95998 8.52882 2.78665 6.1312 3.61332 3.73358C3.83801 3.08189 4.47046 2.77384 5.12304 2.99824C6.56175 3.49297 8.00046 3.98769 9.43917 4.48242C9.63039 4.54818 9.74234 4.72568 9.71312 4.9089C9.67972 5.10423 9.51287 5.24351 9.31498 5.23649C9.26119 5.23494 9.20656 5.21616 9.15192 5.19737C7.74357 4.71308 6.33521 4.22879 4.92685 3.7445C4.61422 3.63699 4.43061 3.72643 4.32296 4.03863C3.50779 6.40291 2.69262 8.76719 1.87745 11.1315C1.7698 11.4437 1.85936 11.627 2.17199 11.7346C4.53949 12.5487 6.90394 13.3617 9.27144 14.1758C9.5871 14.2844 9.76967 14.198 9.87836 13.8827C10.3685 12.4611 10.8587 11.0395 11.3488 9.61795C11.4084 9.44517 11.5433 9.34918 11.72 9.34889C11.8794 9.34945 12.0266 9.44417 12.0738 9.60279C12.0954 9.67802 12.1036 9.77239 12.0785 9.84513C11.5715 11.3254 11.0646 12.8056 10.5485 14.2827C10.3573 14.8273 9.6935 15.1279 9.13397 14.9389C7.90971 14.5213 6.68755 14.0976 5.46435 13.677Z"
                                            fill="white"/>
                                        <path
                                            d="M16.1019 5.18953C16.0375 5.16739 15.9977 5.15369 15.9578 5.13998C15.2006 4.8796 14.4434 4.61921 13.6862 4.35882C13.5053 4.29663 13.3965 4.10489 13.4332 3.92889C13.4742 3.73036 13.6448 3.59355 13.8426 3.6067C13.8847 3.60402 13.9276 3.61878 13.9705 3.63353C15.0558 4.00672 16.1441 4.38096 17.2293 4.75414C17.4654 4.83531 17.5669 5.03826 17.4865 5.27127C17.1102 6.36275 16.7349 7.45117 16.3586 8.54266C16.2804 8.76954 16.0721 8.87626 15.8606 8.80352C15.6429 8.72867 15.5446 8.51653 15.625 8.28352C15.8818 7.53849 16.1398 6.79039 16.3966 6.04536C16.4104 6.0055 16.4252 5.96258 16.4474 5.8982C16.3866 5.92531 16.3475 5.93929 16.3104 5.9574C14.0264 7.07192 11.7423 8.18644 9.45831 9.30096C9.33875 9.3593 9.2194 9.39714 9.08557 9.34769C8.94053 9.29096 8.85649 9.18661 8.83344 9.03465C8.81251 8.87655 8.87023 8.74893 9.00448 8.65792C9.04888 8.6286 9.0973 8.60753 9.14265 8.5854C11.4143 7.47691 13.6901 6.36641 15.9617 5.25793C16.0019 5.24088 16.0401 5.21971 16.1019 5.18953Z"
                                            fill="white"/>
                                    </svg>
                                    </div>
                                    <TranslatableText
                                        dictionary={{
                                            EN: "MegaAccountability",
                                            DE: "MegaAccountability",
                                        }}
                                    ></TranslatableText>
                                </span>
                            </a>
                        </div>
                    </div>
                    <div
                        className={styles["second-view-native"]}
                        ref={Tex5}
                    >
                        <TranslatableText
                            dictionary={{
                                EN: "View native app example:",
                                DE: "Beispiel ansehen:",
                            }}
                        ></TranslatableText>
                    </div>
                </div>
            </div>

            <div className={styles["web-app-block"]}>
                <div className={styles["web-app-content"]}>
                    <div className={styles["stroke-web-block"]}>
                        <div className={styles["stroke-web"]}>
                            <img
                                src={Web}
                                alt="vector"
                                ref={webVector}
                            />
                            <h2 ref={web}>
                                <TranslatableText
                                    dictionary={{
                                        EN: " WEB APP",
                                        DE: "WEB-APP",
                                    }}
                                ></TranslatableText>
                            </h2>
                        </div>
                        <div className={styles["rectangle-web"]} ref={block2}>
                            <div className={styles["text-web"]} ref={Tex2}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "Looks like a website, behaves like an app, can be put into the “shell” of a native app",
                                        DE: "Sieht aus wie eine Website, verhält sich wie eine App, kann in die “Hülle” einer nativen App gesteckt werden",
                                    }}
                                ></TranslatableText>
                                <div
                                    className={styles["view-web"]}
                                    ref={Tex6}
                                >
                                    <TranslatableText
                                        dictionary={{
                                            EN: "View web app example:",
                                            DE: "Beispiel ansehen:",
                                        }}
                                    ></TranslatableText>
                                </div>
                            </div>
                        </div>
                        <div className={styles["laptop-block"]}>
                            <a
                                href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/weboffice`}
                                target="_blank">
                                <img src={comp} alt="comp" className={styles.comp} ref={Comp1}/>
                            </a>
                            <div className={styles["laptop-signature"]}>
                                <a
                                    href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/weboffice`}
                                    className={styles.webOffice}
                                    ref={Tex10}
                                    target="_blank"
                                >
                                    <div className={styles.icon2}>
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.55434 13.2351C7.33855 13.5837 6.12276 13.9323 4.90388 14.2818C4.23427 14.4739 3.61973 14.1358 3.42774 13.4663C2.72702 11.0226 2.02719 8.58199 1.32736 6.14138C1.13714 5.478 1.47829 4.86261 2.14173 4.67237C3.60438 4.25296 5.06703 3.83355 6.52968 3.41415C6.72408 3.3584 6.91773 3.43978 6.99902 3.6068C7.08385 3.78617 7.02716 3.99609 6.86103 4.10384C6.81608 4.13342 6.76053 4.14935 6.70499 4.16528C5.2732 4.57584 3.84141 4.9864 2.40961 5.39696C2.09178 5.48809 1.99273 5.66676 2.08386 5.98456C2.77396 8.39123 3.46407 10.7979 4.15417 13.2046C4.2453 13.5224 4.42397 13.6214 4.7418 13.5303C7.14869 12.8401 9.5525 12.1508 11.9594 11.4607C12.2803 11.3686 12.3802 11.1931 12.2882 10.8722C11.8733 9.42508 11.4583 7.97799 11.0434 6.5309C10.993 6.35503 11.0484 6.1989 11.1929 6.09735C11.3238 6.00639 11.4988 5.99961 11.6286 6.10265C11.6895 6.15197 11.7504 6.22466 11.7716 6.29871C12.2065 7.80354 12.6413 9.30837 13.0669 10.8158C13.223 11.3721 12.8519 11.9994 12.285 12.1653C11.0423 12.525 9.7979 12.8785 8.55434 13.2351Z" fill="white"/>
                                            <path d="M11.6595 1.18752C11.5941 1.20629 11.5536 1.21791 11.513 1.22953C10.7432 1.45027 9.9734 1.67101 9.20357 1.89176C9.01969 1.94449 8.82047 1.84962 8.74943 1.68423C8.66901 1.49788 8.7302 1.28781 8.89977 1.18515C8.93269 1.15882 8.97633 1.14631 9.01996 1.1338C10.1233 0.817426 11.2297 0.500163 12.333 0.183794C12.573 0.114979 12.7727 0.22322 12.8407 0.460411C13.1593 1.57146 13.477 2.6794 13.7956 3.79045C13.8618 4.0214 13.7525 4.22837 13.5374 4.29004C13.3162 4.35349 13.1138 4.23588 13.0458 3.99869C12.8283 3.24031 12.6099 2.4788 12.3925 1.72041C12.3809 1.67984 12.3683 1.63615 12.3495 1.57061C12.3153 1.62771 12.2913 1.66161 12.2713 1.69774C11.0404 3.92185 9.80957 6.14595 8.5787 8.37006C8.51427 8.48649 8.43823 8.58597 8.3002 8.62217C8.14881 8.65883 8.02004 8.62145 7.91388 8.51003C7.80594 8.39237 7.77993 8.2546 7.83763 8.10295C7.85716 8.05344 7.88472 8.00838 7.90916 7.96422C9.13336 5.75216 10.3598 3.53608 11.584 1.32402C11.6071 1.28699 11.6262 1.24774 11.6595 1.18752Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <TranslatableText
                                        dictionary={{
                                            EN: "WebOffice+",
                                            DE: "WebOffice+",
                                        }}
                                    ></TranslatableText>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles["second-view-web"]}
                        ref={Tex6}
                    >
                        <TranslatableText
                            dictionary={{
                                EN: "View web app example:",
                                DE: "Beispiel ansehen:",
                            }}
                        ></TranslatableText>
                    </div>
                </div>
                <div className={styles["to-portfolio-button"]}>
                    <Ellipse/>
                </div>
            </div>
            <div className={styles["hybrid-block"]}>
                <div className={styles["hybrid-block-content"]}>
                    <div className={styles["stroke-hybrid-block"]}>
                        <div className={styles["stroke-hybrid"]}>
                            <img
                                src={hybrid}
                                alt="vector"
                                ref={HybridVector}
                            />
                            <h2 ref={Hybrid}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "HYBRID APP",
                                        DE: "HYBRIDE APP",
                                    }}
                                ></TranslatableText>
                            </h2>
                        </div>

                        <div className={styles["rectangle-hybrid"]} ref={block3}>
                            <div className={styles["text-hybrid"]} ref={Tex3}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "A native app supplemented by reusable platform-independent web components",
                                        DE: "Eine native App, die durch wiederverwendbare, plattformunabhängige Webkomponenten ergänzt wird",
                                    }}
                                ></TranslatableText>
                                <div className={styles["rectangle-hybrid-signature"]}>
                                    <div
                                        className={styles["view-hybrid"]}
                                        ref={Tex7}
                                    >
                                        <TranslatableText
                                            dictionary={{
                                                EN: "View hybrid app example:",
                                                DE: "Beispiel ansehen:",
                                            }}
                                        ></TranslatableText>
                                        <div className={styles.icon} ref={Tex9}>
                                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.99167 11.9986C3.72688 11.9986 2.4621 11.9986 1.1941 11.9986C0.497507 11.9986 -6.10352e-05 11.5043 -6.10352e-05 10.8077C-6.10352e-05 8.26556 -6.10352e-05 5.7266 -6.10352e-05 3.18763C-6.10352e-05 2.49752 0.497507 2 1.18768 2C2.70927 2 4.23087 2 5.75246 2C5.9547 2 6.11842 2.1316 6.15052 2.31456C6.18262 2.51036 6.07026 2.69653 5.88087 2.75431C5.82951 2.77036 5.77172 2.77036 5.71394 2.77036C4.22445 2.77036 2.73496 2.77036 1.24546 2.77036C0.914821 2.77036 0.770366 2.9148 0.770366 3.24541C0.770366 5.74906 0.770366 8.25272 0.770366 10.7564C0.770366 11.087 0.914821 11.2314 1.24546 11.2314C3.74935 11.2314 6.25003 11.2314 8.75392 11.2314C9.08777 11.2314 9.23223 11.0902 9.23223 10.7564C9.23223 9.25097 9.23223 7.74557 9.23223 6.24017C9.23223 6.05721 9.32853 5.92239 9.49545 5.86462C9.64633 5.81326 9.81647 5.85499 9.91277 5.9898C9.95771 6.054 9.99623 6.14066 9.99623 6.2177C9.99944 7.78409 10.0027 9.35048 9.99623 10.9169C9.99302 11.4946 9.46335 11.9954 8.87269 11.9986C7.57902 12.0018 6.28534 11.9986 4.99167 11.9986Z" fill="white"/>
                                                <path d="M10.6641 0.779209C10.596 0.779209 10.5539 0.779209 10.5117 0.779209C9.71088 0.779209 8.91003 0.779209 8.10918 0.779209C7.91788 0.779209 7.75253 0.633108 7.72983 0.454539C7.70389 0.253243 7.82061 0.0681808 8.01191 0.0162335C8.05082 0 8.09621 0 8.1416 0C9.28938 0 10.4404 0 11.5882 0C11.8378 0 11.9999 0.159089 11.9999 0.405838C11.9999 1.56167 11.9999 2.71425 11.9999 3.87007C11.9999 4.11033 11.8378 4.27916 11.6141 4.27916C11.3839 4.27916 11.2218 4.11033 11.2218 3.86358C11.2218 3.07463 11.2218 2.28243 11.2218 1.49348C11.2218 1.45128 11.2218 1.40582 11.2218 1.33764C11.1732 1.3831 11.1407 1.40907 11.1115 1.43829C9.31532 3.23697 7.51908 5.03564 5.72285 6.83432C5.62882 6.92847 5.52831 7.00314 5.38565 6.9999C5.23002 6.9934 5.11654 6.92198 5.04521 6.78562C4.97388 6.64276 4.98685 6.50315 5.08411 6.37328C5.11654 6.33108 5.15544 6.29536 5.19111 6.25965C6.97762 4.47071 8.76737 2.67853 10.5539 0.889597C10.5863 0.860377 10.6155 0.82791 10.6641 0.779209Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className={styles.das}>DAS</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles["laptop-block"]}>
                            <div className={styles["laptop"]}>
                                <a
                                    href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/das`}
                                    target="_blank">
                                    <img src={laptopAndPhone} className={styles["laptop-and-phone"]} alt="phone"
                                         ref={Comp2}/>
                                </a>
                                <div className={styles["laptop-signature"]}>
                                    <a
                                        href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/das`}
                                        className={styles["das-comp"]}
                                        ref={Tex8}
                                        target="_blank"
                                    >
                                        DAS
                                    </a>
                                    <div className={styles.icon3} ref={Tex9}>
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99167 11.9986C3.72688 11.9986 2.4621 11.9986 1.1941 11.9986C0.497507 11.9986 -6.10352e-05 11.5043 -6.10352e-05 10.8077C-6.10352e-05 8.26556 -6.10352e-05 5.7266 -6.10352e-05 3.18763C-6.10352e-05 2.49752 0.497507 2 1.18768 2C2.70927 2 4.23087 2 5.75246 2C5.9547 2 6.11842 2.1316 6.15052 2.31456C6.18262 2.51036 6.07026 2.69653 5.88087 2.75431C5.82951 2.77036 5.77172 2.77036 5.71394 2.77036C4.22445 2.77036 2.73496 2.77036 1.24546 2.77036C0.914821 2.77036 0.770366 2.9148 0.770366 3.24541C0.770366 5.74906 0.770366 8.25272 0.770366 10.7564C0.770366 11.087 0.914821 11.2314 1.24546 11.2314C3.74935 11.2314 6.25003 11.2314 8.75392 11.2314C9.08777 11.2314 9.23223 11.0902 9.23223 10.7564C9.23223 9.25097 9.23223 7.74557 9.23223 6.24017C9.23223 6.05721 9.32853 5.92239 9.49545 5.86462C9.64633 5.81326 9.81647 5.85499 9.91277 5.9898C9.95771 6.054 9.99623 6.14066 9.99623 6.2177C9.99944 7.78409 10.0027 9.35048 9.99623 10.9169C9.99302 11.4946 9.46335 11.9954 8.87269 11.9986C7.57902 12.0018 6.28534 11.9986 4.99167 11.9986Z" fill="white"/>
                                            <path d="M10.6641 0.779209C10.596 0.779209 10.5539 0.779209 10.5117 0.779209C9.71088 0.779209 8.91003 0.779209 8.10918 0.779209C7.91788 0.779209 7.75253 0.633108 7.72983 0.454539C7.70389 0.253243 7.82061 0.0681808 8.01191 0.0162335C8.05082 0 8.09621 0 8.1416 0C9.28938 0 10.4404 0 11.5882 0C11.8378 0 11.9999 0.159089 11.9999 0.405838C11.9999 1.56167 11.9999 2.71425 11.9999 3.87007C11.9999 4.11033 11.8378 4.27916 11.6141 4.27916C11.3839 4.27916 11.2218 4.11033 11.2218 3.86358C11.2218 3.07463 11.2218 2.28243 11.2218 1.49348C11.2218 1.45128 11.2218 1.40582 11.2218 1.33764C11.1732 1.3831 11.1407 1.40907 11.1115 1.43829C9.31532 3.23697 7.51908 5.03564 5.72285 6.83432C5.62882 6.92847 5.52831 7.00314 5.38565 6.9999C5.23002 6.9934 5.11654 6.92198 5.04521 6.78562C4.97388 6.64276 4.98685 6.50315 5.08411 6.37328C5.11654 6.33108 5.15544 6.29536 5.19111 6.25965C6.97762 4.47071 8.76737 2.67853 10.5539 0.889597C10.5863 0.860377 10.6155 0.82791 10.6641 0.779209Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["second-rectangle-hybrid-signature"]}>
                        <div className={styles["view-hybrid"]} ref={Tex7}>
                            <TranslatableText
                                dictionary={{
                                    EN: "View hybrid app example:",
                                    DE: "Beispiel ansehen:",
                                }}
                            ></TranslatableText>
                            <a
                                href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/das`}
                                className={styles["das-block"]}
                                target="_blank">
                                <div className={styles.icon}>
                                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.99167 11.9986C3.72688 11.9986 2.4621 11.9986 1.1941 11.9986C0.497507 11.9986 -6.10352e-05 11.5043 -6.10352e-05 10.8077C-6.10352e-05 8.26556 -6.10352e-05 5.7266 -6.10352e-05 3.18763C-6.10352e-05 2.49752 0.497507 2 1.18768 2C2.70927 2 4.23087 2 5.75246 2C5.9547 2 6.11842 2.1316 6.15052 2.31456C6.18262 2.51036 6.07026 2.69653 5.88087 2.75431C5.82951 2.77036 5.77172 2.77036 5.71394 2.77036C4.22445 2.77036 2.73496 2.77036 1.24546 2.77036C0.914821 2.77036 0.770366 2.9148 0.770366 3.24541C0.770366 5.74906 0.770366 8.25272 0.770366 10.7564C0.770366 11.087 0.914821 11.2314 1.24546 11.2314C3.74935 11.2314 6.25003 11.2314 8.75392 11.2314C9.08777 11.2314 9.23223 11.0902 9.23223 10.7564C9.23223 9.25097 9.23223 7.74557 9.23223 6.24017C9.23223 6.05721 9.32853 5.92239 9.49545 5.86462C9.64633 5.81326 9.81647 5.85499 9.91277 5.9898C9.95771 6.054 9.99623 6.14066 9.99623 6.2177C9.99944 7.78409 10.0027 9.35048 9.99623 10.9169C9.99302 11.4946 9.46335 11.9954 8.87269 11.9986C7.57902 12.0018 6.28534 11.9986 4.99167 11.9986Z" fill="white"/>
                                        <path d="M10.6641 0.779209C10.596 0.779209 10.5539 0.779209 10.5117 0.779209C9.71088 0.779209 8.91003 0.779209 8.10918 0.779209C7.91788 0.779209 7.75253 0.633108 7.72983 0.454539C7.70389 0.253243 7.82061 0.0681808 8.01191 0.0162335C8.05082 0 8.09621 0 8.1416 0C9.28938 0 10.4404 0 11.5882 0C11.8378 0 11.9999 0.159089 11.9999 0.405838C11.9999 1.56167 11.9999 2.71425 11.9999 3.87007C11.9999 4.11033 11.8378 4.27916 11.6141 4.27916C11.3839 4.27916 11.2218 4.11033 11.2218 3.86358C11.2218 3.07463 11.2218 2.28243 11.2218 1.49348C11.2218 1.45128 11.2218 1.40582 11.2218 1.33764C11.1732 1.3831 11.1407 1.40907 11.1115 1.43829C9.31532 3.23697 7.51908 5.03564 5.72285 6.83432C5.62882 6.92847 5.52831 7.00314 5.38565 6.9999C5.23002 6.9934 5.11654 6.92198 5.04521 6.78562C4.97388 6.64276 4.98685 6.50315 5.08411 6.37328C5.11654 6.33108 5.15544 6.29536 5.19111 6.25965C6.97762 4.47071 8.76737 2.67853 10.5539 0.889597C10.5863 0.860377 10.6155 0.82791 10.6641 0.779209Z" fill="white"/>
                                    </svg>
                                </div>
                                <span className={styles.das}>DAS</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles["second-to-portfolio-button"]}>
                <Ellipse/>
            </div>
        </div>
    );
}

export default Capabilities;
