import React, {useRef, useEffect} from "react";
import Title from "../Title";
import style from "../Team/Team.module.scss";
import vectorLink from "../../asset/logo/Vector.svg";
import vectorTop from "../../asset/img/arrowTop.svg";
import vectorMiddle from "../../asset/img/arrowMiddle.svg";
import vectorBottom from "../../asset/img/arrowBottom.svg";
import arrowForMobileDesignersBlock from "../../asset/img/arrowForMobileDesignersBlock.svg";
import teamManagers from "../../asset/img/photo/managers.png";
import teamDesigners from "../../asset/img/photo/photos2.png";
import teamDevelopers from "../../asset/img/photo/photos3.png";
import teamQA from "../../asset/img/photo/photos4.png";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import useLanguage from "../Context/useLanguage";

gsap.registerPlugin(ScrollTrigger);

function Team() {
    const {currentLanguage} = useLanguage();
    const blockOne = useRef(null);
    const photos1 = useRef(null);
    const photos2 = useRef(null);
    const photos3 = useRef(null);
    const photos4 = useRef(null);
    const word1 = useRef(null);
    const word2 = useRef(null);
    const word3 = useRef(null);
    const word4 = useRef(null);
    const arrowOne = useRef(null);
    const arrowTwo = useRef(null);
    const arrowTree = useRef(null);
    const arrowFour = useRef(null);
    const Text = useRef(null);
    useEffect(() => {
        const el1 = blockOne.current;
        const el2 = photos1.current;
        const el3 = photos2.current;
        const el4 = photos3.current;
        const el5 = photos4.current;
        const el6 = word1.current;
        const el7 = word2.current;
        const el8 = word3.current;
        const el9 = word4.current;
        const el10 = arrowOne.current;
        const el11 = arrowTwo.current;
        const el12 = arrowTree.current;
        const el13 = arrowFour.current;
        const el14 = Text.current;
        gsap.fromTo(
            el1,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );
        gsap.fromTo(
            el4,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );
        gsap.fromTo(
            el5,
            {y: 150, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el5,
                },
            }
        );

        gsap.fromTo(
            el6,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
        );
        gsap.fromTo(
            el7,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el7,
                },
            }
        );
        gsap.fromTo(
            el8,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el8,
                },
            }
        );
        gsap.fromTo(
            el9,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el9,
                },
            }
        );
        gsap.fromTo(
            el10,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el10,
                },
            }
        );
        gsap.fromTo(
            el11,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el11,
                },
            }
        );
        gsap.fromTo(
            el12,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el12,
                },
            }
        );
        gsap.fromTo(
            el13,
            {rotate: 90},
            {
                rotate: 0,
                scrollTrigger: {
                    trigger: el13,
                },
            }
        );
        gsap.fromTo(
            el14,
            {x: -250, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el14,
                },
            }
        );
    }, []);

    return (
        <div className={style.team} id="Team">
            <Title className={style["title-team"]}>
                <TranslatableText
                    dictionary={{
                        EN: "OUR TEAM",
                        DE: "UNSER TEAM",
                    }}
                ></TranslatableText>
            </Title>
            <div className={style["team-wrapper"]}>
                <div className={style["first-line"]}>
                    <div className={style["manager-block"]}>
                        <div className={style["manager-text"]} ref={word1}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Managers",
                                    DE: "Projektleiter",
                                }}
                            ></TranslatableText>
                        </div>
                        <div className={style["photos-management"]} ref={photos1}>
                            <img
                                alt="team"
                                src={teamManagers}
                            />
                        </div>
                        <div className={style["vector1-wrapper"]}>
                            <img
                                className={style.vector1}
                                alt="vector"
                                src={vectorTop}
                                ref={arrowOne}
                            />
                        </div>
                    </div>
                    <div className={style["designers-block"]}>
                        <div className={style["vector2-wrapper"]}>
                            <img
                                className={style.vector2}
                                alt="vector"
                                src={vectorMiddle}
                                ref={arrowTwo}
                            />
                        </div>
                        <div className={style["designers-text"]} ref={word2}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Designers",
                                    DE: "Designer",
                                }}
                            ></TranslatableText>
                        </div>
                        <div className={style["photos-designers"]} ref={photos2}>
                            <img
                                alt="team"
                                src={teamDesigners}
                            />
                        </div>
                        <div className={style["mobile-vector2-wrapper"]}>
                            <img
                                className={style["mobile-vector2"]}
                                alt="vector"
                                src={arrowForMobileDesignersBlock}
                                ref={arrowTwo}
                            />
                        </div>
                    </div>
                    <Title className={style["desktop-title-team"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "OUR TEAM",
                                DE: "UNSER TEAM",
                            }}
                        ></TranslatableText>
                    </Title>
                </div>
                <div className={style["second-line"]}>
                    <div className={style["desktop-btns-container"]}>
                        <a
                            className={style["btns-text"]}
                            ref={Text}
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/about-us`}
                            target="_blank"
                        >
                            <div style={{textDecoration: 'underline'}}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "Click here",
                                        DE: "Hier klicken,",
                                    }}
                                ></TranslatableText>
                            </div>
                            <div>
                                <TranslatableText
                                    dictionary={{
                                        EN: "to see the whole team",
                                        DE: "um das ganze Team zu sehen",
                                    }}
                                ></TranslatableText>
                            </div>
                        </a>
                        <div className={style["vector-wrapper"]}>
                            <img
                                src={vectorLink}
                                alt="vector"
                                className={style.vector}
                                ref={arrowFour}
                            />
                        </div>
                    </div>
                    <div className={style["developers-block"]}>
                        <div className={style["text-developers"]} ref={word3}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Developers",
                                    DE: "Entwickler",
                                }}
                            ></TranslatableText>
                        </div>
                        <div className={style["photos-developers"]} ref={photos3}>
                            <img
                                alt="team"
                                src={teamDevelopers}
                                className={style["team-developers"]}
                            />
                        </div>
                        <div className={style["vector3-wrapper"]}>
                            <img
                                className={style.vector3}
                                alt="vector"
                                src={vectorBottom}
                                ref={arrowTree}
                            />
                        </div>
                        <div className={style["vector4-wrapper"]}>
                            <img
                                className={style.vector4}
                                alt="vector"
                                src={vectorTop}
                                ref={arrowOne}
                            />
                        </div>
                    </div>
                    <div className={style["qa-block"]}>
                        <div className={style["text-qa"]} ref={word4}>
                            <TranslatableText
                                dictionary={{
                                    EN: "QA & DevOps",
                                    DE: "QA & DevOps",
                                }}
                            ></TranslatableText>
                        </div>

                        <div className={style["photos-qa"]} ref={photos4}>
                            <img alt="team" src={teamQA} className={style["team-qa"]}/>
                        </div>
                    </div>
                </div>
                <div className={style["btns-container"]}>
                    <div className={style.wrapper}>
                        <a
                            className={style["btns-text"]}
                            ref={Text}
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/about-us`}
                            target="_blank"
                        >
                            <div style={{textDecoration: 'underline'}}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "Click here",
                                        DE: "Hier klicken,",
                                    }}
                                ></TranslatableText>
                            </div>
                            <div>
                                <TranslatableText
                                    dictionary={{
                                        EN: "to see the whole team",
                                        DE: "um das ganze Team zu sehen",
                                    }}
                                ></TranslatableText>
                            </div>
                        </a>
                        <div className={style["vector-wrapper"]}>
                            <img
                                src={vectorLink}
                                alt="vector"
                                className={style.vector}
                                ref={arrowFour}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Team;
