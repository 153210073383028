import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    value: []
};

export const describesSlice = createSlice({
    name: 'describes',
    initialState,
    reducers: {
        setDescribe: (state, action) => {
            if (!state.value.includes(action.payload)) {
                state.value.push(action.payload);
            } else {
                state.value = state.value.filter((value) => value !== action.payload);
            }
        }
    }
});

export default describesSlice.reducer;

export const {setDescribe} = describesSlice.actions;