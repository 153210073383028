import React, {useRef, useEffect} from "react";
import style from "../Advantages/Advantages.module.scss";
import wave from "../../asset/img/wave.svg";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import arrow from "../../asset/logo/vector-button-orange.svg";
import gsap from "gsap";
import "../../App.css";
import Title from "../Title";

gsap.registerPlugin(ScrollTrigger);

const develop = [
    {
        EN: "own designers, developers and testers",
        DE: "eigene Designer, Entwickler und Tester"
    },

    {
        EN: "all-around expertise",
        DE: "umfassendes Fachwissen"
    },
    {
        EN: "well-matched team members",
        DE: "gut aufeinander abgestimmte Teammitglieder"
    },
];

const adapt = [
    {
        EN: "project start in 1 week after offer",
        DE: "Projektstart ab 1 Woche nach Angebot"
    },

    {
        EN: "Junior to Senior developers price range",
        DE: "Preisspanne von Junior- bis Senior-Entwickler"
    },
    {
        EN: "fast team upscaling",
        DE: "schnelles Team-Upscaling"
    },
];

const proj = [
    {
        EN: "website development and app development",
        DE: "Website-Entwicklung und App-Entwicklung"
    },

    {
        EN: "established structure and management tools",
        DE: "etablierte Struktur und Management-Tools"
    },
    {
        EN: "SCRUM development methodology",
        DE: "Entwicklung nach SCRUM-Methode"
    },
];

const guar = [
    {
        EN: "flexible adaptation to project requirements",
        DE: "flexible Anpassung an Projektanforderungen"
    },

    {
        EN: "response within 24 h",
        DE: "Antwort innerhalb von 24 Stunden"
    },
    {
        EN: "maintenance offer after project finish",
        DE: "Wartungsangebot nach Projektabschluss"
    },
];

function Advantages() {
    const developItems = develop.map((advantage, index) => (
        <li key={`${advantage}_${index}`} className={style.items}>
            <TranslatableText dictionary={advantage}></TranslatableText>
        </li>
    ));
    const adaptItems = adapt.map((advantage, index) => (
        <li key={`${advantage}_${index}`} className={style.items}>
            <TranslatableText dictionary={advantage}></TranslatableText>
        </li>
    ));

    const projectItems = proj.map((advantage, index) => (
        <li key={`${advantage}_${index}`} className={style.items}>
            <TranslatableText dictionary={advantage}></TranslatableText>
        </li>
    ));
    const guartItems = guar.map((advantage, index) => (
        <li key={`${advantage}_${index}`} className={style.items}>
            <TranslatableText dictionary={advantage}></TranslatableText>
        </li>
    ));

    const blockOne = useRef(null);
    const backOne = useRef(null);
    const blockTwo = useRef(null);
    const backTwo = useRef(null);
    const backThree = useRef(null);
    const backFour = useRef(null);
    const Text = useRef(null);
    const image = useRef(null);
    const but = useRef(null);
    const bgBut = useRef(null);

    useEffect(() => {
        const el1 = blockOne.current;
        const el2 = blockTwo.current;
        const el3 = backOne.current;
        const el4 = backTwo.current;
        const el5 = backThree.current;
        const el6 = backFour.current;
        const el7 = Text.current;
        const el8 = image.current;
        const el9 = but.current;
        const el10 = bgBut.current;

        gsap.fromTo(
            el1,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
        gsap.fromTo(
            el3,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );
        gsap.fromTo(
            el4,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );
        gsap.fromTo(
            el5,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el5,
                },
            }
        );
        gsap.fromTo(
            el6,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
        );
        gsap.fromTo(
            el7,
            {y: 100, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el7,
                },
            }
        );
        gsap.fromTo(
            el8,
            {x: -100},
            {
                x: 0,
                scrollTrigger: {
                    trigger: el8,
                },
            }
        );
        gsap.fromTo(
            el9,
            {rotate: 90},
            {
                rotate: 0,
                scrollTrigger: {
                    trigger: el9,
                },
            }
        );
        gsap.fromTo(
            el10,
            {rotate: 90},
            {
                rotate: 0,
                scrollTrigger: {
                    trigger: el10,
                },
            }
        );
    }, []);


    return (
        <div className={style["advantages-block"]} id="Advantages">
            <Title className={style["advantages-title"]}>
                <TranslatableText
                    dictionary={{
                        EN: "Our advantages",
                        DE: "Unsere Vorteile",
                    }}
                ></TranslatableText>
            </Title>

            <div className={style["advantages-content"]}>
                <div className={style["row-top"]} ref={blockOne}>
                    <div className={style.wave}>
                        <img src={wave} alt='wave' className={style.svg} ref={image}/>
                    </div>
                    <div className={style["block-development"]}>
                        <div className={style["title-development"]}>
                            <TranslatableText
                                dictionary={{
                                    EN: "In-house development",
                                    DE: "In-House Entwicklung",
                                }}
                            ></TranslatableText>
                        </div>
                        <ul className={style["content-development"]}>{developItems}</ul>
                        <div className={style["bg-development"]} ref={backOne}></div>
                    </div>
                    <div className={style["block-adjustable"]}>
                        <div className={style["title-adjustable"]}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Adjustable costs and deadlines",
                                    DE: "Anpassbare Kosten und Termine",
                                }}
                            ></TranslatableText>
                        </div>
                        <ul className={style["content-adjustable"]}>{adaptItems}</ul>
                        <div className={style["bg-adjustable"]} ref={backTwo}></div>
                    </div>
                </div>

                <div className={style["row-bottom"]} ref={blockTwo}>
                    <div className={style["block-projects"]}>
                        <div className={style["title-projects"]}>
                            <TranslatableText
                                dictionary={{
                                    EN: "300+ projects",
                                    DE: "300+ Projekte",
                                }}
                            ></TranslatableText>
                        </div>
                        <ul className={style["content-projects"]}>{projectItems}</ul>
                        <div className={style["bg-projects"]} ref={backThree}></div>
                    </div>
                    <div className={style["block-guarantee"]}>
                        <div className={style["title-guarantee"]}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Project handover guarantee",
                                    DE: "Projektübergabe-Garantie",
                                }}
                            ></TranslatableText>
                        </div>
                        <ul className={style["content-guarantee"]}>{guartItems}</ul>
                        <div className={style["bg-guarantee"]} ref={backFour}></div>
                    </div>
                    <div className={style["desktop-offer-button-wrapper"]} ref={but}>
                        <div className={style["content-wrapper"]}>
                            <div className={style["wrapper"]}>
                                <a href="#form" className={style["offer-button"]}>
                                    <div className={style["text-wrapper"]}>
                                        <TranslatableText
                                            dictionary={{
                                                EN: "GET AN OFFER",
                                                DE: "ANGEBOT ERHALTEN",
                                            }}
                                        ></TranslatableText>
                                    </div>
                                    <div className={style["vector-wrapper"]}>
                                        <img src={arrow} alt="vector" className={style.vector}/>
                                    </div>
                                </a>
                            </div>
                            </div>
                    </div>
                </div>
                <div className={style["offer-button-wrapper"]} ref={but}>
                    <div className={style["content-wrapper"]}>
                        <a href="#form" className={style["offer-button"]}>
                            <div className={style["text-wrapper"]}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "GET AN OFFER",
                                        DE: "ANGEBOT ERHALTEN",
                                    }}
                                ></TranslatableText>
                            </div>
                            <div className={style["vector-wrapper"]}>
                                <img src={arrow} alt="vector" className={style.vector}/>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advantages;
