import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {TranslatableText} from "../TranslatableText";
import logo from "../../asset/logo/NITGLogo.svg";
import style from "./Header.module.scss";
import Burger from "../Burger";
import Menu from "../Menu";
import useLanguage from "../Context/useLanguage";
import arrow from '../../asset/logo/EN-arrow.svg';
import Language from "../Language";

function Header() {
    const {setLanguage, currentLanguage} = useLanguage();
    return (
        <div className={style.header} id="Header">
            <div className={style["header-content"]}>
                <a href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/`}
                   className={style["logo-wrapper"]} target="_blank">
                    <img src={logo} alt="logo" className={style.logo}/>
                </a>
                <Menu/>
                <Language bsPrefix={style.dropdown}>
                    <Dropdown.Toggle id="dropdown-autoclose-true" className={style.language}>
                        <TranslatableText
                            dictionary={{
                                EN: "EN",
                                DE: "DE",
                            }}
                        ></TranslatableText>
                        <img src={arrow} alt='arrow' className={style.arrow}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                        className={style["dropdown-menu"]}
                        onClick={(e) => {
                            setLanguage(e.target.value);
                            localStorage.setItem('lang', e.target.value);
                        }}
                    >
                        <Dropdown.Item className={style["dropdown-item"]} as={"button"} value="EN">
                            EN
                        </Dropdown.Item>
                        <Dropdown.Item className={style["dropdown-item"]} as={"button"} value="DE">
                            DE
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Language>

                <a href="#form" className={style["btn-header"]}>
                    <TranslatableText
                        dictionary={{
                            EN: "GET AN OFFER",
                            DE: "ANGEBOT ERHALTEN",
                        }}
                    ></TranslatableText>
                </a>
                <Burger/>
            </div>
        </div>
    );
}

export default Header;
