import React, {useState, useEffect} from "react";
import style from "../../Components/Form/Form.module.scss";
import check from "../../asset/logo/check.svg";
import {TranslatableText} from "../TranslatableText";
import {useDispatch, useSelector} from 'react-redux';
import {setEmail} from "../../store/reducer/emailsSlice";
import {setName} from "../../store/reducer/namesSlice";
import useResults from '../../hook/useResults';
import useLanguage from "../Context/useLanguage";
import {
    setCheckboxError,
    setEmailInputError,
    setNameInputError
} from "../../store/reducer/formErrorsSlice";
import {ERROR, SUCCESS} from "../../constants";
import {setFreeText} from "../../store/reducer/freeTextSlice";

function Form() {
    const {currentLanguage} = useLanguage();
    const {fetchResults} = useResults();
    const dispatch = useDispatch();
    const {nameInputError, emailInputError, checkboxError} = useSelector((state) => state.formErrors);
    const [searchName, setSearchName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const [languageFreeText, setLanguageFreeText] = useState('')
    const [languageEmail, setLanguageEmail] = useState('')

    const handleName = (e) => {
        setSearchName(e.target.value);
        if (e.target.value) {
            dispatch(setNameInputError(SUCCESS));
        } else {
            dispatch(setNameInputError(ERROR));
        }
    };

    const handleEmail = (e) => {
        if (/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(e.target.value)) {
            setSearchEmail(e.target.value);
            dispatch(setEmailInputError(SUCCESS));
        } else {
            dispatch(setEmailInputError(ERROR));
        }
    };

    const handleFreeText = (e) => {
        dispatch(setFreeText(e.target.value));
    };

    const handleChangeCheckbox = (e) => {
        if (e.target.checked) {
            setChecked(true);
            dispatch(setCheckboxError(SUCCESS));
            return;
        }
        setChecked(false);
        dispatch(setCheckboxError(ERROR));
    };

    const [isSuccess, setIsSuccess] = useState(false);

    const translation = () => {
        if (currentLanguage === 'EN') {
            setLanguageFreeText('Free text');
            setLanguageEmail('Enter email*');
        } else {
            setLanguageFreeText('Freitext');
            setLanguageEmail('E-Mail eingeben*');
        }
    }


    const fetchSearch = () => {
        dispatch(setEmail(searchEmail));
        dispatch(setName(searchName));
    };


    const sendUserInfo = async () => {
        if (!nameInputError) {
            dispatch(setNameInputError(ERROR));
        }
        if (!emailInputError) {
            dispatch(setEmailInputError(ERROR));
        }
        if (!checked) {
            dispatch(setCheckboxError(ERROR));
        }
        if (nameInputError === SUCCESS && emailInputError === SUCCESS && checkboxError === SUCCESS) {
            const response = await fetchResults()
            setIsSuccess(response.success);
        }
    };

    useEffect(() => {
        fetchSearch();
        translation();
    })

    return (
        <div className={style["form-wrapper"]}>
            <form className={style["form-content"]} id={'feedback-from'}>
                <div className={style.group1}>

                    <input
                        type="text"
                        className={[style.control, nameInputError === ERROR ? style["border-error"] : ''].join(" ")}
                        aria-describedby="emailHelp"
                        required={true}
                        placeholder="Name*"
                        onChange={handleName}
                        maxLength="255"
                    />
                    <input
                        type="email"
                        className={[style.email, emailInputError === ERROR ? style["border-error"] : ''].join(" ")}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder={languageEmail}
                        onChange={handleEmail}
                        maxLength="255"
                    />
                </div>
                <div className={style.group2}>
                    <textarea
                        className={style.freeText}
                        id="freeText"
                        aria-describedby="freeTextHelp"
                        placeholder={languageFreeText}
                        onChange={handleFreeText}
                        maxLength="255"
                        rows={4}
                    />
                </div>
            </form>

            {
                nameInputError === ERROR || emailInputError === ERROR
                    ? (<div className={style["inputs-error"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "Please fill out all fields.",
                                DE: "Bitte füllen Sie alle Felder aus.",
                            }}
                        ></TranslatableText>
                    </div>)
                    : ''
            }

            <div className={style["checkbox-wrapper"]}>
                <div className={style["checkbox-block"]}>
                    <input type="checkbox"
                           id="checkbox-id"
                           checked={checked}
                           className={checkboxError === ERROR ? style["checkbox-error"] : ''}
                           onChange={handleChangeCheckbox}/>
                    <label htmlFor="checkbox-id"/>
                    <span>
                        <TranslatableText
                            dictionary={{
                                EN: 'I here by consent to the <a href="https://northitgroup.com/en/data-policy" target="_blank">processing of data</a> for contact purposes by North IT Group GmbH.',
                                DE: 'Hiermit stimme ich der <a href="https://northitgroup.com/de/data-policy" target="_blank">Datenverarbeitung</a> zur Kontaktaufnahme durch die North IT Group GmbH zu.',
                            }}
                        ></TranslatableText>
                    </span>
                </div>
                {
                    checkboxError === ERROR
                        ? (<div className={style["checkbox-error"]}>
                            <TranslatableText
                                dictionary={{
                                    EN: "Please consent to the processing of data.",
                                    DE: "Bitte stimmen Sie der Datenverarbeitung zu.",
                                }}
                            ></TranslatableText>
                        </div>)
                        : ''
                }
            </div>

            <div>
                <div>
                    {isSuccess ? (
                        <div className={style["send-button-wrapper"]}>
                            <button
                                type="submit"
                                className={style.primary}
                                onClick={() => {
                                    setIsSuccess(false)
                                }}
                            >
                                <img alt="arrow" id="arrow" src={check}/>
                                <div
                                    className={[style["text-button"], style["button-success"]].join(" ")}
                                    id="send-text">
                                    <TranslatableText
                                        dictionary={{
                                            EN: "sent successfully",
                                            DE: "erfolgreich gesendet",
                                        }}
                                    ></TranslatableText>
                                </div>
                            </button>
                        </div>
                    ) : (

                        <div className={style["send-button-wrapper"]}>
                            <button
                                type="submit"
                                className={style.primary}
                                onClick={async () => sendUserInfo()}
                            >
                                <div className={[style["text-button"],
                                    nameInputError === SUCCESS && emailInputError === SUCCESS && checkboxError === SUCCESS
                                        ? style["button-success"] : ''].join(" ")}
                                     id="send-text">
                                    <TranslatableText
                                        dictionary={{
                                            EN: "Send",
                                            DE: "Senden",
                                        }}
                                    ></TranslatableText>
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Form;
