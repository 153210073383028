import {createSlice} from '@reduxjs/toolkit';
import {cloneDeep} from 'lodash';

const initialState = {
    values: []
};

export const emailSlice = createSlice({
    name: 'email',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.values = cloneDeep(action.payload);
        }
    }
});

export default emailSlice.reducer;

export const {setEmail} = emailSlice.actions;
