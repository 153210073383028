import React, {useRef, useEffect} from "react";
import zeppelin from "../../asset/img/zeppelin.svg";
import wh from "../../asset/img/wh.svg";
import tab from "../../asset/img/tab.svg";
import oa from "../../asset/img/oa.svg";
import evosis from "../../asset/img/evosis.svg";
import eagle from "../../asset/img/eagle.svg";
import ebd from "../../asset/img/ebd.svg";
import morEnergy from "../../asset/img/morEnergy.svg";
import stotz from "../../asset/img/stotz.svg";
import style from "./Row.module.scss";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function Row() {
    const rowOne = useRef(null);
    const rowTwo = useRef(null);
    useEffect(() => {
        const el1 = rowOne.current;
        const el2 = rowTwo.current;
        gsap.fromTo(
            el1,
            {x: -1050},
            {
                x: 0,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
        gsap.fromTo(
            el2,
            {x: 1050},
            {
                x: 0,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );
    }, []);
    return (
        <div className={style.clients}>
            <div className={style["row-top"]} ref={rowOne}>
                <div className={style["logos-top"]}>
                    <div className={style.components}>
                        <img src={zeppelin} alt="zeppelin"/>
                        <img src={wh} alt="wh"/>
                        <img src={tab} alt="tab"/>
                        <img src={oa} alt="oa"/>
                        <img src={evosis} alt="evosis"/>
                        <img src={eagle} alt="eagle"/>
                        <img src={ebd} alt="ebd"/>
                        <img src={morEnergy} alt="morEnergy"/>
                        <img src={stotz} alt="stotz"/>
                        <img src={zeppelin} alt="zeppelin"/>
                        <img src={wh} alt="wh"/>
                        <img src={tab} alt="tab"/>
                        <img src={oa} alt="oa"/>
                        <img src={evosis} alt="evosis"/>
                        <img src={eagle} alt="eagle"/>
                        <img src={ebd} alt="ebd"/>
                        <img src={morEnergy} alt="morEnergy"/>
                        <img src={stotz} alt="stotz"/>
                    </div>
                </div>
            </div>
            <div className={style["row-bottom"]} ref={rowTwo}>
                <div className={style["logos-bottom"]}>
                    <div className={style.components}>
                        <img src={evosis} alt="evosis"/>
                        <img src={eagle} alt="eagle"/>
                        <img src={ebd} alt="ebd"/>
                        <img src={morEnergy} alt="morEnergy"/>
                        <img src={stotz} alt="stotz"/>
                        <img src={zeppelin} alt="zeppelin"/>
                        <img src={wh} alt="wh"/>
                        <img src={tab} alt="tab"/>
                        <img src={oa} alt="oa"/>
                        <img src={evosis} alt="evosis"/>
                        <img src={eagle} alt="eagle"/>
                        <img src={ebd} alt="ebd"/>
                        <img src={morEnergy} alt="morEnergy"/>
                        <img src={stotz} alt="stotz"/>
                        <img src={zeppelin} alt="zeppelin"/>
                        <img src={wh} alt="wh"/>
                        <img src={tab} alt="tab"/>
                        <img src={oa} alt="oa"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Row;
