import React, {useState} from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {TranslatableText} from "../TranslatableText";
import style from "./Burger.module.scss";
import useLanguage from "../Context/useLanguage";
import line from '../../asset/logo/line-burger.svg'
import buttonBurger from '../../asset/logo/menu.svg'
import burgerActive from '../../asset/logo/close.svg'

function Burger() {
    const {setLanguage, currentLanguage} = useLanguage();
    const [count, setCount] = useState(buttonBurger);
    return (
        <Dropdown bsPrefix={style['burger-drop']}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" bsPrefix={style['toggle-drop']}>
                <img src={count} alt=''
                     onClick={() => {
                         if (count === buttonBurger) {
                             setCount(burgerActive);
                         } else {
                             setCount(buttonBurger);
                         }

                     }}/>
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix={style[count === burgerActive ? 'dropdown-menuBurger' : 'hide-dropdown']}>
                <div className={style.list} onClick={() => setCount(buttonBurger)}>
                    <Dropdown.Item href="#App"
                                   bsPrefix={style['burger-item']}
                    >
                        <TranslatableText
                            dictionary={{
                                EN: "App types",
                                DE: "App-Typen",
                            }}
                        >
                            App types
                        </TranslatableText>
                    </Dropdown.Item>
                    <img src={line} alt='line' className={style['line-burg']}/>
                    <Dropdown.Item href="#Team"
                                   bsPrefix={style['burger-item']}
                    > <TranslatableText
                        dictionary={{
                            EN: "Team",
                            DE: "Team",
                        }}
                    >
                        Team
                    </TranslatableText></Dropdown.Item>
                    <img src={line} alt='line' className={style['line-burg']}/>
                    <Dropdown.Item href="#Advantages"
                                   bsPrefix={style['burger-item']}
                    > <TranslatableText
                        dictionary={{
                            EN: "Advantages",
                            DE: "Vorteile",
                        }}
                    >
                        Advantages
                    </TranslatableText></Dropdown.Item>
                    <img src={line} alt='line' className={style['line-burg']}/>
                    <Dropdown.Item href="#form"
                                   bsPrefix={style['burger-item']}
                    > <TranslatableText
                        dictionary={{
                            EN: "Contact us",
                            DE: "Jetzt kontaktieren",
                        }}
                    ></TranslatableText></Dropdown.Item>

                    <img src={line} alt='line' className={style['line-burg']}/>
                    <div className={style["language-block"]}>
                        <div
                            onClick={(event) => {
                                setLanguage(event.target.innerText);
                                localStorage.setItem('lang', event.target.innerText);
                            }}
                            className={`${style['language-block-item']} ${currentLanguage === 'EN' ? style.active : ''}`}>EN
                        </div>
                        <div>|</div>
                        <div
                            onClick={(event) => {
                                setLanguage(event.target.innerText);
                                localStorage.setItem('lang', event.target.innerText);
                            }}
                            className={`${style['language-block-item']} ${currentLanguage === 'DE' ? style.active : ''}`}>DE
                        </div>
                    </div>
                    <img src={line} alt='line' className={style['line-burg']}/>
                    <a href="#form" className={style["btn-header"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "GET AN OFFER",
                                DE: "ANGEBOT ERHALTEN",
                            }}
                        ></TranslatableText>
                    </a>
                    <a href="tel: +49(156)78573735" className={style.phone}>
                        +49(156)78573735
                    </a>
                </div>


            </Dropdown.Menu>
        </Dropdown>
    );
}

export default Burger;
