import React from "react";
import style from "./Button.module.scss";

function Button({children, className, ref, onClick}) {
    return (
        <button onClick={onClick} className={[style.buttons, className].join(" ")} ref={ref}>
            {children}
        </button>
    );
}

export default Button;
