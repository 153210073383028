import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    freeText: ''
};

export const freeTextSlice = createSlice({
    name: 'freeText',
    initialState,
    reducers: {
        setFreeText: (state, action) => {
            state.freeText = action.payload;
        }
    }
});

export default freeTextSlice.reducer;

export const {setFreeText} = freeTextSlice.actions;
