import React from "react";
import {TranslatableText} from "../TranslatableText";
import style from "./Menu.module.scss";

function Menu() {
    return (
        <nav className={style["menu-header"]}>
            <ul>
                <a href="#App">
                    <li className={style["menu-items"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "App types",
                                DE: "App-Typen",
                            }}
                        ></TranslatableText>
                    </li>
                </a>
                <a href="#Team">
                    <li className={style["menu-items"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "Team",
                                DE: "Team",
                            }}
                        ></TranslatableText>
                    </li>
                </a>
                <a href="#Advantages">
                    <li className={style["menu-items"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "Advantages",
                                DE: "Vorteile",
                            }}
                        ></TranslatableText>
                    </li>
                </a>
                <a href="#form">
                    <li className={style["menu-items"]}>
                        <TranslatableText
                            dictionary={{
                                EN: "Contact us",
                                DE: "Jetzt kontaktieren",
                            }}
                        ></TranslatableText>
                    </li>
                </a>
            </ul>
        </nav>
    );
}

export default Menu;
