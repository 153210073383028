import {combineReducers, configureStore} from '@reduxjs/toolkit';
import emailsSlice from './reducer/emailsSlice';
import freeTextSlice from './reducer/freeTextSlice';
import namesSlice from './reducer/namesSlice';
import describesSlice from './reducer/describesSlice';
import formErrorsSlice from "./reducer/formErrorsSlice";


const rootReducer = combineReducers({
    email: emailsSlice,
    freeText: freeTextSlice,
    name: namesSlice,
    describe: describesSlice,
    formErrors: formErrorsSlice
});

export const setupStore = () => {
    return configureStore({
        reducer: rootReducer
    });
};
