import axios from 'axios';
import {useSelector} from 'react-redux';

const useResults = () => {
    const email = useSelector((state) => state.email.values);
    const freeText = useSelector((state) => state.freeText.freeText);
    const name = useSelector((state) => state.name.values);
    const describe = useSelector((state) => state.describe.value);
    const modifiedDescribe = describe.length > 0 ? describe.join(', ') : 'Nothing selected';
    const fetchResults = async () => {
        return axios.post(`https://northitgroup.com/api/request`, {
            business: modifiedDescribe,
            contact: "email",
            day: "any day",
            email: email,
            fromTime: '0:00',
            interested: "App Development",
            name: name,
            number: 'phone',
            toTime: "23:00",
            site: 'landing page 1',
            description: freeText
        })
            .then(res => {
                return res.data;
            })
    }

    return {fetchResults};
};

export default useResults;
