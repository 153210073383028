import React, {useRef, useEffect} from "react";
import styles from "../Contact/Contact.module.scss";
import line from "../../asset/img/line.svg";
import horizontalLine from "../../asset/img/horizontalLine.svg";
import inst from "../../asset/logo/inst.svg";
import inn from "../../asset/logo/in.svg";
import fe from "../../asset/logo/fe.svg";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Title from "../Title";
import gsap from "gsap";
import Button from "../Button";
import Form from "../Form";
import {useDispatch, useSelector} from 'react-redux';
import {setDescribe} from "../../store/reducer/describesSlice";
import {BUSINESS_BUTTONS} from "../../constants";

gsap.registerPlugin(ScrollTrigger);


function Contact() {
    const blockOne = useRef(null);
    const dispatch = useDispatch();
    const describe = useSelector((state) => state.describe.value);
    useEffect(() => {
        const el1 = blockOne.current;
        gsap.fromTo(
            el1,
            {x: 200, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el1,
                },
            }
        );
    }, []);

    const toggleButton = (id) => {
        const currentButton = BUSINESS_BUTTONS.filter((button) => button.id === id);
        dispatch(setDescribe(currentButton[0].englishContent));
    };
    const Items = BUSINESS_BUTTONS.map((btn) => (
        <Button
            key={btn.id}
            className={
                describe.includes(btn.englishContent)
                    ? styles["button-active"]
                    : styles["button-component"]
            }
            onClick={() => {
                toggleButton(btn.id)
            }}
        >
            <TranslatableText
                dictionary={{
                    EN: btn.englishContent,
                    DE: btn.germanContent,
                }}
            ></TranslatableText>
        </Button>
    ));
    return (
        <div className={styles.form} id="form">
            <Title className={styles.title}>
                <TranslatableText
                    dictionary={{
                        EN: "Contact us",
                        DE: "Kontaktieren Sie uns",
                    }}
                ></TranslatableText>
            </Title>
            <div className={styles["form-block"]}>
                <div className={styles.choice}>
                    <div className={styles.text}>
                        <TranslatableText
                            dictionary={{
                                EN: "Choose your features. <br>" +
                                    "We will get back to you within 24 hours with an effort estimate.",
                                DE: "Wählen Sie Ihre Features.<br>" +
                                    "Wir melden uns innerhalb von 24 Stunden mit einer Aufwandsabschätzung zurück.",
                            }}
                        ></TranslatableText>
                        <div className={styles.buttons}>{Items}</div>
                    </div>
                    <Form/>
                </div>
                <div className={styles.line}>
                    <img alt="line" src={line} className={styles["vertical-line"]}/>
                    <img alt="line" src={horizontalLine} className={styles["horizontal-line"]}/>
                </div>
                <div className={styles.img} ref={blockOne}>
                    <a
                        href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQEmoJLe-ok0NgAAAYHTq1FIDyeyCW4Ugiaxmxg7UWfpfgemLKvhznypDxoRJ02tAE67Q0jLcAYTY2DYOZAKAjVUOjYaYoyDrTz-NaGtGk4yl_gNZpDOQAbRpXMv7LRcdqKj6ww=&original_referer=&sessionRedirect=https%3A%2F%2Fde.linkedin.com%2Fcompany%2Fnorth-it-group%3Foriginal_referer%3Dhttps%253A%252F%252Fnorthitgroup.com%252F"
                        target="_blank">
                        <img className={styles.linkedin} alt="line" src={inn}/>
                    </a>
                    <a href="https://instagram.com/northitgroup?igshid=YmMyMTA2M2Y=" target="_blank">
                        <img className={styles.instagram} alt="line" src={inst}/>
                    </a>
                    <a href="https://m.facebook.com/northitgroup/" target="_blank">
                        <img className={styles.facebook} alt="line" src={fe}/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
