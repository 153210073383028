import React, {useRef, useEffect} from "react";
import style from "./MainPage.module.scss";
import phone from "../../asset/img/app.png";
import phone1 from "../../asset/img/app1.png";
import phone2 from "../../asset/img/app2.png";
import vector from "../../asset/logo/row-button.svg";
import goToPortfolioArrow from "../../asset/img/goToPortfolioArrow.svg";
import goToPortfolioGermanWrapper from "../../asset/img/goToPortfolioGermanWrapper.svg";
import goToPortfolioEnglishWrapper from "../../asset/img/goToPortfolioEnglishWrapper.svg";
import ellipse from "../../asset/img/ellipse.svg";
import {TranslatableText} from "../TranslatableText";
import Carousel from "react-bootstrap/Carousel";
import Title from "../Title";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import useLanguage from "../Context/useLanguage";

gsap.registerPlugin(ScrollTrigger);

function Main() {
    const {currentLanguage} = useLanguage();
    const Text = useRef(null);
    const Bt = useRef(null);
    const BgBt = useRef(null);
    const ellipse1 = useRef(null);
    const ellipse2 = useRef(null);
    const Phone = useRef(null);

    useEffect(() => {
        const el2 = Text.current;
        const el3 = Bt.current;
        const el4 = BgBt.current;
        const el5 = ellipse1.current;
        const el6 = ellipse2.current;
        const el7 = Phone.current;

        gsap.fromTo(
            el2,
            {y: 200, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );

        gsap.fromTo(
            el2,
            {y: 200, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el2,
                },
            }
        );

        gsap.fromTo(
            el3,
            {x: -1000, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el3,
                },
            }
        );

        gsap.fromTo(
            el4,
            {x: -1000, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el4,
                },
            }
        );

        gsap.fromTo(
            el5,
            {x: -1000, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el5,
                },
            }
        );

        gsap.fromTo(
            el6,
            {x: 100, opacity: 0},
            {
                x: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
        );

        gsap.fromTo(
            el7,
            {y: 300, opacity: 0},
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: el6,
                },
            }
        );
    }, []);

    return (
        <div className={style["main-content"]} id="Main">
            <Title className={style["main-title"]}>
                <TranslatableText
                    dictionary={{
                        EN: "APP DEVELOPMENT",
                        DE: "APP-ENTWICKLUNG",
                    }}
                ></TranslatableText>
            </Title>
            <div className={style["divided-main-title"]}>
                <TranslatableText
                    dictionary={{
                        EN: "APP DEVELOP-MENT",
                        DE: "APP-ENTWICK-LUNG",
                    }}
                ></TranslatableText>
            </div>

            <div className={style.contentBlock}>
                <div className={style.contentText}>
                    <div className={style["text-main"]} ref={Text}>
                        <TranslatableText
                            dictionary={{
                                EN:
                                    "We bring your ideas to life in accordance with " +
                                    '<span className="orange">UI/UX principles and GDPR requirements.</span>' +
                                    " Establishing an online shop or creating your platform from scratch, building a large assessment & report system or an easy 3-5 screen app – we take " +
                                    `<span className="orange">responsibility for each step of development.</span>` +
                                    " Even after go-live" +
                                    `<span className="orange"> we are by your side</span>` +
                                    " with support and maintenance.",
                                DE:
                                    "Wir erwecken Ihre Ideen zum Leben" +
                                    '<span className="orange"> unter Berücksichtigung der UI/UX-Prinzipien und der DSGVO-Anforderungen.</span>' +
                                    " Ob Sie ein umfangreiches System oder eine einfache 3-5-Screen-App brauchen - wir übernehmen die" +
                                    '<span className="orange"> Verantwortung für jeden Entwicklungsschritt.</span>' +
                                    " Auch nach dem Go-Live stehen wir Ihnen mit" +
                                    '<span className="orange"> Support und Wartung</span>' +
                                    " zur Seite.",
                            }}
                        ></TranslatableText>
                    </div>
                    <div className={style["button-get-wrapper"]}>
                        <a href="#form" ref={Bt} className={style["button-get"]}>
                            <span className={style["text-button"]}>
                                <TranslatableText
                                    dictionary={{
                                        EN: "GET AN OFFER",
                                        DE: "ANGEBOT ERHALTEN",
                                    }}
                                ></TranslatableText>
                            </span>
                            <img src={vector} alt="vector" className={style.vector}/>
                        </a>
                    </div>
                </div>


                <div className={style["scroll-container"]}>
                    <div className={style.circle} ref={ellipse2}>
                        <a
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/portfolio`}
                            className={style["ellipse-block"]}
                            target="_blank">
                            <img src={ellipse} alt="ellipse" className={style.ellipse}/>
                            <span className={style["to-portfolio-button"]}>
                                <img className={style["to-portfolio-arrow"]} src={goToPortfolioArrow}
                                     alt="goToPortfolioArrow"/>
                                {
                                    currentLanguage === 'DE'
                                        ? <img className={style["to-portfolio-button-wrapper"]}
                                               src={goToPortfolioGermanWrapper} alt="goToPortfolioBlock"/>
                                        : <img className={style["to-portfolio-button-wrapper"]}
                                               src={goToPortfolioEnglishWrapper} alt="goToPortfolioBlock"/>
                                }
                            </span>
                        </a>
                        <a
                            href={`https://northitgroup.com/${currentLanguage.toLowerCase()}/portfolio`}
                            className={style.carousel}
                            target="_blank">
                            <Carousel fade controls={false} indicators={false}>
                                <Carousel.Item ref={Phone}>
                                    <img src={phone1} alt="phone" className={style.phonePhoto}/>
                                </Carousel.Item>
                                <Carousel.Item ref={Phone}>
                                    <img src={phone2} alt="phone" className={style.phonePhoto}/>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img src={phone} alt="phone" className={style.phonePhoto}/>
                                </Carousel.Item>
                            </Carousel>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
