import React from "react";
import style from "../Advertising/Advertising.module.scss";
import {TranslatableText} from "../TranslatableText";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Title from "../Title";
import Row from "../Row";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

function Advertising() {
    return (
        <div className={style["advertising-content"]}>
            <Title className={style["title-advertising"]}>
                <TranslatableText
                    dictionary={{
                        EN: "Our clients",
                        DE: "Unsere Kunden",
                    }}
                    value="our-clients"
                ></TranslatableText>
            </Title>
            <Row/>
        </div>
    );
}

export default Advertising;
