import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import style from "./Language.module.scss";
import "../../App.css";

function Language({children, bsPrefix}) {
    return (
        <Dropdown
            bsPrefix={[style.dropdown, bsPrefix].join(" ").trim()}
        >
            {children}
        </Dropdown>
    );
}

export default Language;
